import React from 'react';
import { Link } from 'react-router-dom';

const HeroEight = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-purple text-white'
        style={{
          background:
            "url('assets/img/app-two-mockup-bg.png')no-repeat center center / cover",
        }}
      >
        <div className='container'>
          <div className='row justify-content-xl-between align-items-center'>
            <div className='col-lg-5 col-xl-5 col-xl-5 col-md-10'>
              <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
                <h5 className='text-warning'>Coming may 2024</h5>
                <h1 className='fw-bold display-5'>
                  Managed FTP filetransfer
                </h1>
                <p className='lead'>
                  Combine the scalability of your cloud filestorage service with the flexibility of a SFTP server. All accessible through an easy-to-use interface.
                </p>
                
              </div>
            </div>
            <div className='col-lg-5 col-md-8 mt-5'>
              <div className='hero-app-img position-relative text-center'>
                <img
                  src='assets/images/mac-screen.png'
                  alt='app screen'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroEight;
