import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureOne = () => {
  return (
    <>
      <section className='feature-promo ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='Our Features'
                description='For a more detailed and complete list of features see our documentation.'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                  <i className='fal fa-layer-group fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Webinterface</h3>
                  <p>
                    Allows easy setup and maintenance of users and datasources.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-danger'>
                  <i className='fal fa-shield-check fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>IP Whitelisting</h3>
                  <p>
                    Restrict access to your data to specific IP addresses.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-dark'>
                  <i className='fal fa-code fa-2x text-white'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Logging</h3>
                  <p>
                    A full audit log of all actions taken by users.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
          <div className='col-lg-4 col-md-6'>
            <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
              <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
                <i className='fal fa-layer-group fa-2x text-white'></i>
              </div>
              <div className='feature-info-wrap'>
                <h3 className='h5'>Easy onboarding</h3>
                <p>
                  Easily onboard new users to the platform.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
              <div className='feature-icon icon-center pb-5 rounded-custom bg-danger'>
                <i className='fal fa-shield-check fa-2x text-white'></i>
              </div>
              <div className='feature-info-wrap'>
                <h3 className='h5'>Multiple protocols</h3>
                <p>
                  Support filetransfers through FTP, SFTP, FTPS and HTTPS.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
              <div className='feature-icon icon-center pb-5 rounded-custom bg-dark'>
                <i className='fal fa-code fa-2x text-white'></i>
              </div>
              <div className='feature-info-wrap'>
                <h3 className='h5'>API </h3>
                <p> 
                    Automate your user/datasource management through the API.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
        <div className='col-lg-4 col-md-6'>
          <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
            <div className='feature-icon icon-center pb-5 rounded-custom bg-primary'>
              <i className='fal fa-layer-group fa-2x text-white'></i>
            </div>
            <div className='feature-info-wrap'>
              <h3 className='h5'>SSL Certificates</h3>
              <p>
                Automate the renewal of your SSL certificates through Let's encrypt.
              </p>
            </div>
          </div>
        </div>
       <div className='col-lg-4 col-md-6'>
          <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
            <div className='feature-icon icon-center pb-5 rounded-custom bg-danger'>
              <i className='fal fa-shield-check fa-2x text-white'></i>
            </div>
            <div className='feature-info-wrap'>
              <h3 className='h5'>Dashboard</h3>
              <p>
                Keep an overview of your data and users with the dashboard.
              </p>
            </div>
          </div>
        </div>
         {/* 
        <div className='col-lg-4 col-md-6'>
          <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
            <div className='feature-icon icon-center pb-5 rounded-custom bg-dark'>
              <i className='fal fa-code fa-2x text-white'></i>
            </div>
            <div className='feature-info-wrap'>
              <h3 className='h5'></h3>
              <p>
              </p>
            </div>
          </div>
        </div> */}
      </div>
        </div>
      </section>
    </>
  );
};

export default FeatureOne;
