
import { BrowserRouter, Routes, Route } from "react-router-dom";

import About from "../pages/About";
import Features from "../pages/Features";

import RequestDemo from "../pages/RequestDemo";
import Home from "../pages/Home";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga'

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/features" element={<Features />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/contact" element={<RequestDemo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
