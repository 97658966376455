import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaFive from '../components/cta/CtaFive';
import CtaFour from '../components/cta/CtaFour';

import FeatureFive from '../components/features/FeatureFive';
import Process from '../components/process/Process';
import TestimonialThree from '../components/testimonial/TestimonialThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEight from './HeroEight';

const HomeAppTwo = () => {
  return (
    <Layout>
      <PageMeta title='FilePulse | Managed SFTP File Transfer' />
      <Navbar navDark />
      <HeroEight />
      <FeatureFive />
      <Process/>
      <CtaFive />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeAppTwo;
