import React from 'react';
import OurStory from '../components/about/OurStory';
import PageMeta from '../components/common/PageMeta';
import CtaTwo from '../components/cta/CtaTwo';
import FeatureImgThree from '../components/features/FeatureImgThree';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEight from './HeroEight';

const About = () => {
  return (
    <Layout>
      <PageMeta title='FilePulse | Managed SFTP File Transfer' />
      <Navbar navDark />
      <HeroEight /> 
      <OurStory />
      <FeatureImgThree />
      <TestimonialTwo />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default About;
