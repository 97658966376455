import React from 'react';
import { Link } from 'react-router-dom';

const CtaFive = () => {
  return (
    <>
      <section
        className='app-two-download-cta ptb-120'
        style={{
          background:
            "url('assets/img/app-download-cta.png')no-repeat center center / cover",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-xl-6 col-md-10'>
              <div className='section-heading text-center'>
                <h2 className='text-white'>
                  Get started today
                </h2>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-xl-10 col-12 row'>
              <div className='action-btns mt-3'>
                <div className='d-inline-block col-2'> </div>
                <div className='d-inline-block  col-3 '>
                    <div className='d-flex align-items-center'>
                      <a href="https://aws.amazon.com/marketplace/pp/prodview-4aij7lubld3c6" target="_blank" rel="noreferrer">
                      <div className='rounded' style={{ border: "1px solid white", padding: '20px',  height: '200px', width: '185px'}}>
                        <img style={{width: '100px', margin: '10px', 'margin-left': '20px'}} src='assets/aws.png'  alt='AWS Marketplace' />
                        <div className='text-white mt-3'>
                          Available on the <br/><b>AWS Marketplace</b>
                        </div>
                      </div>
                      </a>
                    </div>
                </div>

                <div className='d-inline-block  col-3 '>
                    <div className='d-flex align-items-center'>
                      <div className='rounded' style={{ border: "1px solid white", padding: '20px', height: '200px', width: '185px'}}>
                        <img style={{width: '100px', margin: '10px', 'margin-left': '20px', 'margin-top': '0px', 'filter': 'sepia(80%)'}} src='assets/google.png'  alt='AWS Marketplace' />
                        <div className='text-white mt-3'>
                          (Coming soon) <br/><b>Google Cloud </b>
                        </div>
                      </div>
                    </div>
                </div>

                <div className='d-inline-block  col-3 '>
                    <div className='d-flex align-items-center'>
                      <div className='rounded' style={{ border: "1px solid white", padding: '20px', height: '200px', 'filter': 'sepia(80%)'}}>
                        <img style={{width: '100px', margin: '10px', 'margin-left': '20px'}} src='assets/azure.png'  alt='AWS Marketplace' />
                        <div className='text-white mt-3'>
                          (Coming soon) <br/><b>Azure Marketplace</b>
                        </div>
                      </div>‡
                    </div>
                </div>


              
              
              </div>

              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CtaFive;
