import React from 'react';
import { Link } from 'react-router-dom';

const FeatureFive = () => {
  return (
    <>
      <section className='app-two-feature ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='section-heading text-center'>
                <h2>Explore Our Features</h2>
                <p>
                  For a complete list of features see; <Link to='/features'>Features</Link>
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-10'>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-one me-3 mb-4 mb-md-0'>
                      <i className='far fa-file-edit'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Intuitive interface</h3>
                      <p>
                        The webinterface allows users to easily manage users and datasources.
                      </p>
       
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-two me-3 mb-4 mb-md-0'>
                      <i className='far fa-bell'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Easy install</h3>
                      <p>
                        All prebuild images are available on the AWS, Google and Azure marketplaces. 
                      </p>
        
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-three me-3 mb-4 mb-md-0'>
                      <i className='far fa-pen-nib'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Dashboard</h3>
                      <p>
                        Keep an overview of your data and users with the dashboard.
                      </p>
               
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-four me-3 mb-4 mb-md-0'>
                      <i className='far fa-cloud-download-alt'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Audit trail</h3>
                      <p>
                        Keep track of all changes made to your data and transfers.
                      </p>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFive;
