import React from 'react';
import { Link } from 'react-router-dom';

const ContactsForm = () => {
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);


  const submitForm = () => {


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify({ username: state, email: email, company: company, message: message})
      };
      fetch('https://api.filepulse.io', requestOptions)
          .then(response => response.json())
          .then(    setSubmitted(true));
  } 

  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>

          <form action='#' className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    value={state}
                    onChange={(event) => {
                      setState(event.target.value);
                    
                    }}
                    type='text'
                    className='form-control'
                    placeholder='Name'

                    aria-label='name'
                  />
                </div>
              </div>
              <div className='col-sm-6 '>
                <div className='input-group mb-3'>
                  <input
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    aria-label='email'
                  />
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Company website'
                    aria-label='company-website'
                    value={company}
                    onChange={(event) => {
                      setCompany(event.target.value);
                    }}
                  />
                </div>
              </div>
              
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    placeholder='Tell us more about your project, needs and questions.'
                    style={{ height: '120px' }}
                    value={message}
                    onChange={(event) => {
                      setMessage(event.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              
              {submitted && <div >Form submitted successfully, we will reach out shortly. </div>}

              {
                !submitted && 
                <div className='col-12'>
                  <button
                    onClick={submitForm}
                    className='btn btn-primary mt-4 d-block w-100'
                  >
                    Get Started
                  </button>
                </div>
              }
              

            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
