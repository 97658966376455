import React from 'react';
import SectionTitle from '../common/SectionTitle';

const WorkProcess = () => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Process'
                title='How to get started'
                description=''
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
            <img
                  src='assets/images/mac-screen-2.png'
                  alt='app screen'
                  className='img-fluid'
                />
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-folder-tree fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Step 1</span>
                    <h3 className='h5 mb-2'>Installation</h3>
                    <p>
                      Easily install through the marketplace of your cloud provider.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-bezier-curve fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Step 2</span>
                    <h3 className='h5 mb-2'>Connect to your storage</h3>
                    <p>
                        Define the connection to your cloud storage and create user accounts.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-layer-group fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Step 3</span>
                    <h3 className='h5 mb-2'>Start transfering documents</h3>
                    <p>
                        Connect to your SFTP server and start transfering documents.
                    </p>
                  </div>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
