import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='assets/img/filepulse-dark.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='assets/img/filepulse-dark.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
          


                  
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
       
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>

                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Pages</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/home'
                            className='text-decoration-none'
                          >
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/features'
                            className='text-decoration-none'
                          >
                            Features
                          </Link>
                        </li>
                        
                        <li>
                        <a href="https://documentation.filepulse.io" >
                        Documentation
                      </a>
                        </li>
                        {/* <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            About
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to='/contact'
                            className='text-decoration-none'
                          >
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </footer>
    </>
  );
};

export default FooterOne;
